import {mapActions, mapGetters, mapMutations} from "vuex";
//sections
import orderAccorrdion from '../../components/order-accordion/index.vue';

export default {
  name: "history",
  components: {
    orderAccorrdion
  },
  data() {
    return {
      
    }
  },
 
  created() {
    this.fetchHistoryOrders()
  },
  computed: {
    ...mapGetters({
      profileRequestsFlag: 'profile/isLoadingProfile',
      historyOrders: 'profile/historyOrders'
    })
  },

  methods: {
    
    ...mapMutations({}),
    ...mapActions({
      fetchHistoryOrders: 'profile/GET_HISTORY_ORDERS',
    })
  }
}
